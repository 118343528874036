function addEvent(el, e, f) {
    if (el.attachEvent) {
        return el.attachEvent('on' + e, f);
    } else {
        return el.addEventListener(e, f, false);
    }
}

addEvent(window,
            'error',
            function (errorEvent, url, lineNumber, column, errorObj) {
                if (console && console.log) {
                    console.log(arguments);
                }

                if (!errorEvent) {
                    errorEvent = {
                        error: {}
                    };
                }

                var stack = errorEvent.error;


                if (typeof (errorEvent) === "string") {
                    JL("onerrorLogger")
                    .fatalException({
                        "msg": "Exception!",
                        "errorMsg": errorEvent,
                        "url": document.location.href,
                        "file": url,
                        "line number": lineNumber || errorEvent.lineno,
                        "column": column || errorEvent.colno,
                        "errorEvent": errorEvent,
                        "stack": stack
                    },
                        errorObj);
                } else {
                    if (errorEvent.error.stack) {
                        stack = errorEvent.error.stack;
                    }
                    JL("onerrorLogger")
                        .fatalException({
                            "msg": "Exception!",
                            "errorMsg": errorEvent.message,
                            "url": url || errorEvent.filename,
                            "line number": lineNumber || errorEvent.lineno,
                            "column": column || errorEvent.colno,
                            "errorEvent": errorEvent,
                            "stack": stack
                        },
                            errorObj);
                }
                return false;
            });
